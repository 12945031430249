<template>
    <article class="sector" :class="{ 'sector--expand': isToggledSector }">
      <h2 class="sector__title">
        <span>{{ sector.name }}</span>
        <span v-html="getIcon('pencil')" class="icon icon--edit" @click="editSector(sectorIndex)"></span>
        <span v-html="getIcon('caret')" class="icon icon--expander" @click="toggleSector()"></span>
      </h2>
      <div class="images__wrapper">
        <ConstructionScheme
          v-for="image in images"
          :url="image.url"
          :canShow="true"
          :key="image.name"
          @zoomScheme="zoomSectorScheme($event)"
        />
      </div>
      <div class="details__wrapper">
        <h5 class="details__title">{{ $t('message.result.initialData.title') }}</h5>
        <ul class="details__list">
          <li class="details__item" v-for="(field, index) of fields" :key="index">
            <span class="details__item-title">{{ field[0] }}</span>
            <span>{{ field[1] }}</span>
          </li>
        </ul>
        <h5 class="details__title details__title-toggled">{{ $t('message.result.systemDetails.title') }}</h5>
        <ul class="materials__list" :class="{ 'materials__list--expand': isToggledMaterials }">
          <li class="materials__item" v-for="material of materials" :key="material.id">
            <span class="materials__item-title" :class="{ 'without-value': !material.value }">{{ material.title }}</span>
            <span v-if="material.value">{{ material.value }} {{ material.unit }}</span>
          </li>
        </ul>
        <SimpleButton v-if="materials" color="red" padding="40" @btnClicked="toggleMaterials()">
          {{ toggleMaterialsButtonTitle }}
        </SimpleButton>
      </div>
      <h5 class="details__title">{{ getIndexSectorTitle }}</h5>
      <Index :sectorIndexes="sector.indexes" :isResult="true" />
      <img
        v-if="sector.chart"
        :src="`data:image/png;base64,${sector.chart}`"
        class="sector__chart"
        width="100%"
      />
    </article>
</template>

<script>
import ConstructionScheme from '@/components/dump/ConstructionScheme'
import SimpleButton from '@/components/dump/Simple-button'
import Index from '@/components/dump/IndexComponent'
import IconMixin from '@/components/mixin/IconMixin'
import { CalcTypes, ConstructionTypes } from '@/utils/types'
import { getResultDetails } from '@/utils/resultDetails'
import { getSectorConstructionTypeId } from '@/utils/utils'
import { mapGetters } from 'vuex'

export default {
  name: 'Sector',
  props: ['sector'],
  mixins: [IconMixin],
  components: { SimpleButton, ConstructionScheme, Index },
  data: () => ({
    isToggledSector: true,
    isToggledMaterials: false
  }),
  computed: {
    ...mapGetters({
      getConstructionTypes: 'getConstructionTypes'
    }),
    fields() {
      const fields = {
        [`${this.$t('message.result.initialData.calculationType')}`]: this.getCalculationType,
        [`${this.$t('message.constructionTypes.buildings.title')}`]: this.getBuildingTitle,
        [`${this.$t('message.constructionTypes.location.title')}`]: this.sector.constructionType.location?.title,
        [`${this.$t('message.result.initialData.systemTitle')}`]: this.sector.construction?.title || this.sector.system.title
      }

      return Object.entries(fields)
    },
    materials() {
      return this.isProAlgorithm && getSectorConstructionTypeId(this.getConstructionTypes, this.sector) === ConstructionTypes.wall
        ? getResultDetails(this.sector, this.$i18n.messages[this.$i18n.locale].message)
        : this.getConstructionMaterials()
    },
    getCalculationType() {
      const calculationTypeTitle = this.$t('message.store.calculation')
      const currentCalculationType = this.sector.constructionType.type

      return currentCalculationType === this.$t('message.constructionTypes.types.walls')
        ? `${calculationTypeTitle} ${this.$t('message.store.walls')}`
        : `${calculationTypeTitle} ${this.$t('message.store.floor')}`
    },
    getBuildingTitle() {
      return this.sector.constructionType.rating
        ? `${this.sector.constructionType.buildingType} (${this.sector.constructionType.rating})`
        : this.sector.constructionType.buildingType
    },
    toggleMaterialsButtonTitle() {
      return this.$t(`message.buttons.${this.isToggledMaterials ? 'hideDetails' : 'showDetails'}`)
    },
    sectorIndex() {
      return this.$store.state.sectors.findIndex((sector) => sector.id === this.sector.id)
    },
    isProAlgorithm() {
      return this.sector.algorithm === CalcTypes.pro
    },
    getIndexSectorTitle() {
      return this.$t(`message.result.${this.sector.constructionType.type === this.$t('message.constructionTypes.types.walls')
        ? 'indexesCalculationRw'
        : 'indexesCalculationAll'}`
      )
    },
    images() {
      return [
        { name: 'system', url: this.sector.system?.image }
      ]
    }
  },
  methods: {
    toggleMaterials() {
      this.isToggledMaterials = !this.isToggledMaterials
    },
    toggleSector() {
      this.isToggledSector = !this.isToggledSector
    },
    editSector(index) {
      this.$emit('editSector', { index, action: 'edit' })
    },
    zoomSectorScheme(evt) {
      this.$emit('zoomSectorScheme', evt)
    },
    getSeparateValue(thickness, count) {
      const parts = []

      for (let i = 0; i < count; i++) {
        parts.push(`${thickness.value} ${thickness.unitType}`)
      }

      return `(${parts.join(' + ')})`
    },
    getConstructionMaterials() {
      const materials = this.sector.construction?.materials?.map((material) => {
        const hasLayers = material.count !== null
        const commonValue = hasLayers ? material.thickness?.value * material.count : null

        return {
          title: material.title,
          value: commonValue ? commonValue + ' ' + material.thickness.unitType : material.thickness.value,
          unit: commonValue
            ? this.getSeparateValue(material.thickness, material.count)
            : material.thickness.unitType
        }
      })
      const hasTotalThickness = this.sector.construction?.materials?.find((material) => material.title === this.$t('message.result.systemDetails.totalThickness'))

      return (this.sector.construction?.totalThickness && !hasTotalThickness) || this.sector?.totalThickness
        ? [this.getTotalThickness(this.sector.construction?.totalThickness ?? this.sector.totalThickness), ...(materials ?? [])]
        : materials
    },
    getTotalThickness(totalThickness) {
      return {
        title: this.$t('message.result.systemDetails.totalThickness'),
        value: totalThickness.value,
        unit: totalThickness.unitType
      }
    }
  }
}
</script>

<style lang="sass">
%commonText
  @extend %16
  text-align: right
  line-height: 1.3

%gapFill
  content: ''
  flex-grow: 1
  display: inline-block
  margin: 0 rem(14)
  background: url(~@/assets/img/elements/fill.svg) no-repeat 50% 80%
  background-repeat: repeat-x

.sector
  background: $white
  border-radius: $block-radius
  +media((padding: (0: rem(30), 1170: rem(50))))
  &__title
    display: flex
    align-items: flex-start
    margin: 0 0 rem(40)
    span:not(.icon)
      max-width: 85%
      word-break: break-word
    .icon
      width: rem(24)
      height: rem(24)
      margin-left: rem(10)
      &--edit
        margin-left: auto
        cursor: pointer
  .scheme
    display: none
  .materials__list
    display: none
    &--expand
      display: block
  .details, .materials, .images
    &__wrapper
      display: none
      margin-bottom: rem(40)
      .simple-btn
        margin: rem(20) auto 0
    &__title
      display: none
      margin: rem(30) 0 rem(20)
    &__item, .materials__item
      display: flex
      align-items: baseline
      margin-bottom: rem(14)
      @extend %commonText
      &-title, .materials__title
        display: flex
        flex-grow: 1
        text-align: left
        flex-shrink: 0
        min-width: 45%
        &::after
          @extend %gapFill
      &-title.without-value
        &::after
          content: none
  &__chart
    display: none
  .indexes
    margin-bottom: 0
    &__list
      display: none
      flex-flow: column nowrap
      gap: rem(14)
      &-item
        align-items: baseline
        padding: 0
        @extend %commonText
        background: none
        .text
          order: 1
          flex-grow: 0
        .value
          order: 3
        &::after
          @extend %gapFill
          order: 2
          height: rem(2)
          margin: 0
  &--expand
    .sector__title
      .icon--expander
        transform: rotate(180deg)
    .sector__chart
      display: flex
      margin: rem(30) 0
    .indexes__list
      display: flex
    .details
      &__title, &__wrapper
        display: block
    .images
      &__wrapper
        display: grid
        +media((grid-template-columns: (0: 1fr, 530: repeat(2, auto))))
        justify-content: start
        grid-gap: 0 rem(20)
        margin: 0
    .scheme
      display: block
  .details__title-toggled
    display: none
  &--expand:has(.materials__list--expand)
    .details__title-toggled
      display: block
</style>
