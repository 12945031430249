<template>
  <section class="result container">
    <Navigation :steps="navigationConfig"/>
    <div class="result__wrapper">
      <div class="result__container">
        <ul class="sectors__list">
          <li class="sectors__item" v-for="sector of calculatedSectors" :key="sector.id">
            <Sector :sector="sector" @zoomSectorScheme="onZoom($event)" @editSector="navigate($event)"/>
          </li>
        </ul>
      </div>
      <NavigationFooter :validation="validation" @navigate="navigate($event)" isResultPage="true"/>
      <Export @export="exportHandle($event)" @save="saveHandle($event)"/>
    </div>
    <Modal :action="modalAction"
           :isModalOpen="isModalOpen"
           :modalData="modalData"
           :src="src"
           @action="handleModalAction($event)"
    />
  </section>
</template>

<script>
import NavigationMixin from '@/components/mixin/NavigationMixin'
import Sector from '@/components/dump/SectorComponent'
import Navigation from '@/components/smart/Navigation'
import NavigationFooter from '@/components/dump/NavigtionFooter'
import Export from '@/components/smart/Export'
import { mapActions } from 'vuex'
import ModalMixin from '@/components/mixin/ModalMixin'
import Modal from '@/components/dump/Modal'

export default {
  components: { Navigation, Sector, NavigationFooter, Export, Modal },
  mixins: [NavigationMixin, ModalMixin],
  mounted() {
    if (this.$store.getters.getSectors().length === 1 && !this.$store.getters.getSector(0).isCalculated) {
      this.$router.push('/')
    }
    this.sendMetrics()
  },
  methods: {
    ...mapActions({
      getContent: 'getContent',
      getExportDocument: 'getExportDocument'
    }),
    prepareSectors() {
      const fields = [ 'name', 'constructionType', 'base', 'screed', 'indexes', 'system', 'image', 'construction' ]

      return this.calculatedSectors.map((sector) => {
        const exportingSector = {}

        Object.keys(sector).map(key => {
          fields.forEach(field => {
            if (field === key) exportingSector[key] = sector[key]
          })
        })

        return exportingSector
      })
    },
    exportHandle(evt) {
      this.getExportDocument({ action: evt.action, data: this.prepareSectors() })
    },
    saveHandle(evt) {
      // TODO: связка с ЛК
      evt.action === 'save'
        ? console.log('try to save')
        : console.log('try to auth')
    }
  },
  computed: {
    calculatedSectors() {
      return this.sectors.filter(sector => sector.isCalculated)
    }
  }
}
</script>

<style lang="sass">
.result
  width: 100%
  &__wrapper
    display: grid
    grid-template-rows: auto
    grid-template-columns: 1fr
    grid-template-areas: 'sectors' 'footer' 'export'
    +media((grid-template-areas: (0: 'sectors sectors sectors' 'footer footer footer' 'export export export', 768: 'sectors' 'footer' 'export')))
    +media((grid-gap: (0: 0 rem(20), 1170: 0 rem(40))))
    .sectors__list
      width: 100%
  &__container
    display: flex
    grid-area: sectors
  &__aside
    grid-area: aside
    .questions
      background: $white
      +media((width: (0: 100%, 768: 260px, 1024: 300px)))
.sectors
  &__list
    display: flex
    flex-wrap: wrap
    gap: rem(20)
  &__item
    width: 100%
.wizard
  &-footer
    margin-bottom: rem(30)
</style>
