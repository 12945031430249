export function getResultDetails(sector, translation) {
  const details = [sector.facing, sector.isolation].map((param, index) => {
    const type = index ? 'isolationParams' : 'facingParams'

    return Object.entries(param).map(([key, value]) => {
      const unit = materialUnits[`${key}`]
      const isObject = typeof value === 'object'

      return {
        title: translation.constructionParams[`${type}`][`${key}`],
        value: isObject ? value.title : value,
        unit: translation.units[`${unit}`] ?? null
      }
    })
  }).flat()

  if (sector.totalThickness) {
    const { value, unitType: unit } = sector.totalThickness
    details.push({ value, unit, title: translation.result.systemDetails.totalThickness })
  }

  return details
}

const materialUnits = {
  density: 'pa',
  thickness: 'mm',
  sheets: 'pc'
}
